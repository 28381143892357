<template>
  <div class="flex-container non-scrollable">
    <page-title :value="$t('admin.params.company.generalInformationTitle')"/>
    <div class="scrollable">
      <form @submit.prevent="handleSubmit(!v$.$invalid)" class="p-fluid p-5">
        <div class="flex flex-column justify-content-between">
          <div class="flex flex-row justify-center align-items-center mb-3">
            <label :class="{'p-error':v$.companySocialRaison.$invalid && submitted}" class="w-2 block text-900 font-medium"> {{ $t('admin.params.company.socialRaisonLabel') }}</label>
            <div>
              <Dropdown editable :loading="companyLoading" @change="(val) => { companySuggestions = []; autoFillCompany(val)}" v-model="v$.companySocialRaison.$model" placeholder="Nom de l'entreprise" :options="companySuggestions" @filter="(event) => { companySuggestions = []; getAutocomplete(event); companyLoading = true}" option-label="nom_raison_sociale" filter-placeholder="Recherche d'entreprise" :empty-filter-message="searchResultDescription" empty-message="Veuillez effectuer une recherche" filter-match-mode="NO_FILTER" :filter="true" :showClear="false">
                <template #option="data">
                  <span>{{ data.option.nom_raison_sociale }} - SIRET {{ formatSiret(data.option.siege.siret) }}</span>
                </template>
                <template #value="data">
                  <span>{{ data.value?.nom_raison_sociale }}</span>
                </template>
              </Dropdown>
              <div v-if="v$.companySocialRaison.$silentErrors.length !== 0 && submitted" class="mb-2 mt-3">
            <span id="companySocialRaison-error" v-for="(error, index) of v$.companySocialRaison.$silentErrors" :key="index">
              <InlineMessage>{{error.$message}}</InlineMessage>
            </span>
              </div>
            </div>
            <!--          <InputText id="companySocialRaison" v-model="companySocialRaison" :class="{'p-invalid':v$.companySocialRaison.$invalid && submitted}" type="text" class="w-3" />-->
            <!--            <div v-if="v$.companySocialRaison.$error && submitted" class="mb-3">-->
            <!--                <span id="companySocialRaison-error" v-for="(error, index) of v$.companySocialRaison.$errors" :key="index">-->
            <!--                  <InlineMessage>{{error.$message}}</InlineMessage>-->
            <!--                </span>-->
            <!--            </div>-->
          </div>
          <div class="flex flex-row justify-center align-items-center mb-3">
            <label for="companyCommercialName" :class="{'p-error':v$.companyCommercialName.$invalid && submitted}" class="w-2 block text-900 font-medium"> {{ $t('admin.params.company.nameLabel') }}</label>
            <InputText id="companyCommercialName" v-model="companyCommercialName" :class="{'p-invalid':v$.companyCommercialName.$invalid && submitted}" type="text" class="w-3" />
            <div v-if="v$.companyCommercialName.$error && submitted" class="mb-3">
            <span id="companyCommercialName-error" v-for="(error, index) of v$.companyCommercialName.$errors" :key="index">
              <InlineMessage>{{error.$message}}</InlineMessage>
            </span>
            </div>
          </div>
          <div class="flex flex-row justify-center align-items-center mb-3">
            <label for="companyStatus" :class="{'p-error':v$.companyStatus.$invalid && submitted}" class="w-2 block text-900 font-medium"> {{ $t('admin.params.company.statusLabel') }}</label>
            <InputText id="companyStatus" v-model="companyStatus" :class="{'p-invalid':v$.companyStatus.$invalid && submitted}" type="text" class="w-3" />
            <div v-if="v$.companyStatus.$error && submitted" class="mb-3">
            <span id="companyStatus-error" v-for="(error, index) of v$.companyStatus.$errors" :key="index">
              <InlineMessage>{{error.$message}}</InlineMessage>
            </span>
            </div>
          </div>
          <div class="flex flex-row justify-center align-items-center mb-3">
            <label for="companyCapital" :class="{'p-error':v$.companyCapital.$invalid && submitted}" class="w-2 block text-900 font-medium"> {{ $t('admin.params.company.capitalLabel') }}</label>
            <InputText id="companyCapital" v-model="companyCapital" :class="{'p-invalid':v$.companyCapital.$invalid && submitted}" type="text" class="w-3" />
            <div v-if="v$.companyCapital.$error && submitted" class="mb-3">
            <span id="companyCapital-error" v-for="(error, index) of v$.companyCapital.$errors" :key="index">
              <InlineMessage>{{error.$message}}</InlineMessage>
            </span>
            </div>
          </div>
          <div class="flex flex-row justify-center align-items-center mb-3">
            <label for="companyAddress" :class="{'p-error':v$.companyAddress.$invalid && submitted}" class="w-2 block text-900 font-medium"> {{ $t('admin.params.company.addressLabel') }}</label>
            <InputText id="companyAddress" v-model="companyAddress" :class="{'p-invalid':v$.companyAddress.$invalid && submitted}" type="text" class="w-3" />
            <div v-if="v$.companyAddress.$error && submitted" class="mb-3">
            <span id="companyAddress-error" v-for="(error, index) of v$.companyAddress.$errors" :key="index">
              <InlineMessage>{{error.$message}}</InlineMessage>
            </span>
            </div>
          </div>
          <div class="flex flex-row justify-center align-items-center mb-3">
            <label for="companyZipCode" :class="{'p-error':v$.companyZipCode.$invalid && submitted}" class="w-2 block text-900 font-medium"> {{ $t('admin.params.company.zipCodeLabel') }}</label>
            <InputText id="companyZipCode" v-model="companyZipCode" :class="{'p-invalid':v$.companyZipCode.$invalid && submitted}" type="text" class="w-3" />
            <div v-if="v$.companyZipCode.$error && submitted" class="mb-3">
            <span id="companyZipCode-error" v-for="(error, index) of v$.companyZipCode.$errors" :key="index">
              <InlineMessage>{{error.$message}}</InlineMessage>
            </span>
            </div>
          </div>
          <div class="flex flex-row justify-center align-items-center mb-3">
            <label for="companyCity" :class="{'p-error':v$.companyCity.$invalid && submitted}" class="w-2 block text-900 font-medium"> {{ $t('admin.params.company.cityLabel') }}</label>
            <InputText id="companyCity" v-model="companyCity" :class="{'p-invalid':v$.companyCity.$invalid && submitted}" type="text" class="w-3" />
            <div v-if="v$.companyCity.$error && submitted" class="mb-3">
            <span id="companyCity-error" v-for="(error, index) of v$.companyCity.$errors" :key="index">
              <InlineMessage>{{error.$message}}</InlineMessage>
            </span>
            </div>
          </div>
          <div class="flex flex-row justify-center align-items-center mb-3">
            <label for="companyCityRegistrationRCS" :class="{'p-error':v$.companyCityRegistrationRCS.$invalid && submitted}" class="w-2 block text-900 font-medium"> {{ $t('admin.params.company.cityRegistrationRCSLabel') }}</label>
            <InputText id="companyCityRegistrationRCS" v-model="companyCityRegistrationRCS" :class="{'p-invalid':v$.companyCityRegistrationRCS.$invalid && submitted}" type="text" class="w-3" />
            <div v-if="v$.companyCityRegistrationRCS.$error && submitted" class="mb-3">
            <span id="companyCityRegistrationRCS-error" v-for="(error, index) of v$.companyCityRegistrationRCS.$errors" :key="index">
              <InlineMessage>{{error.$message}}</InlineMessage>
            </span>
            </div>
          </div>

<!--          <div class="flex flex-row justify-center align-items-center mb-3">-->
<!--            <label for="companyRcs" :class="{'p-error':v$.companyRcs.$invalid && submitted}" class="w-2 block text-900 font-medium"> {{ $t('admin.params.company.rcsLabel') }}</label>-->
<!--            <InputText id="companyRcs" v-model="companyRcs" :class="{'p-invalid':v$.companyRcs.$invalid && submitted}" type="text" class="w-3" />-->
<!--            <div v-if="v$.companyRcs.$error && submitted" class="mb-3">-->
<!--            <span id="companyRcs-error" v-for="(error, index) of v$.companyRcs.$errors" :key="index">-->
<!--              <InlineMessage>{{error.$message}}</InlineMessage>-->
<!--            </span>-->
<!--            </div>-->
<!--          </div>-->
          <div class="flex flex-row justify-center align-items-center mb-3">
            <label for="companySiret" :class="{'p-error':v$.companySiret.$invalid && submitted}" class="w-2 block text-900 font-medium"> {{ $t('admin.params.company.siretLabel') }}</label>
            <InputText id="companySiret" v-model="companySiret" :class="{'p-invalid':v$.companySiret.$invalid && submitted}" type="text" class="w-3" />
            <div v-if="v$.companySiret.$error && submitted" class="mb-3">
            <span id="companySiret-error" v-for="(error, index) of v$.companySiret.$errors" :key="index">
              <InlineMessage>{{error.$message}}</InlineMessage>
            </span>
            </div>
          </div>
          <div class="flex flex-row justify-center align-items-center mb-3">
            <label for="companyCityRegistrationRCS" :class="{'p-error':v$.companyRepresentative.$invalid && submitted}" class="w-2 block text-900 font-medium"> {{ $t('admin.params.company.representativeLabel') }}</label>
            <InputText id="companyRepresentative" v-model="companyRepresentative" :class="{'p-invalid':v$.companyRepresentative.$invalid && submitted}" type="text" class="w-3" />
            <div v-if="v$.companyRepresentative.$error && submitted" class="mb-3">
            <span id="companyRepresentative-error" v-for="(error, index) of v$.companyRepresentative.$errors" :key="index">
              <InlineMessage>{{error.$message}}</InlineMessage>
            </span>
            </div>
          </div>
          <div class="flex flex-row justify-center align-items-center mb-3">
            <label for="companyEmail" :class="{'p-error':v$.companyEmail.$invalid && submitted}" class="w-2 block text-900 font-medium"> {{ $t('admin.params.company.emailLabel') }}</label>
            <InputText id="companyEmail" v-model="companyEmail" :class="{'p-invalid':v$.companyEmail.$invalid && submitted}" type="text" class="w-3" />
            <div v-if="v$.companyEmail.$error && submitted" class="mb-3">
            <span id="companyEmail-error" v-for="(error, index) of v$.companyEmail.$errors" :key="index">
              <InlineMessage>{{error.$message}}</InlineMessage>
            </span>
            </div>
          </div>
          <div class="flex flex-row justify-center align-items-center mb-3">
            <label for="companyPhone" :class="{'p-error':v$.companyPhone.$invalid && submitted}" class="w-2 block text-900 font-medium"> {{ $t('admin.params.company.phoneLabel') }}</label>
            <InputMask id="companyPhone" v-model="companyPhone" mask="99-99-99-99-99" :class="{'p-invalid':v$.companyPhone.$invalid && submitted}" type="text" class="w-3" />
            <div v-if="v$.companyPhone.$error && submitted" class="mb-3">
            <span id="companyPhone-error" v-for="(error, index) of v$.companyPhone.$errors" :key="index">
              <InlineMessage>{{error.$message}}</InlineMessage>
            </span>
            </div>
          </div>
          <div class="flex flex-row justify-center align-items-center mb-3">
            <label for="companyCityRegistrationRCS" :class="{'p-error':v$.companyProfession.$invalid && submitted}" class="w-2 block text-900 font-medium"> {{ $t('admin.params.company.professionLabel') }}</label>
            <InputText id="companyProfession" v-model="companyProfession" :class="{'p-invalid':v$.companyProfession.$invalid && submitted}" type="text" class="w-3" />
            <div v-if="v$.companyProfession.$error && submitted" class="mb-3">
            <span id="companyProfession-error" v-for="(error, index) of v$.companyProfession.$errors" :key="index">
              <InlineMessage>{{error.$message}}</InlineMessage>
            </span>
            </div>
          </div>
          <div class="flex flex-row justify-center align-items-center mb-3">
            <label for="companyConvention" class="w-2 block text-900 font-medium"> {{ $t('admin.params.company.conventionLabel') }}</label>
            <Dropdown id="companyConvention" v-model="companyConvention" :options="companyConventionOptions" optionLabel="name" optionValue="value" placeholder="Selectionner une convention" />

            <!--          <InputText id="companyConvention" v-model="companyConvention" :class="{'p-invalid':v$.companyConvention.$invalid && submitted}" type="text" class="w-3" />-->
            <!--          <div v-if="v$.companyConvention.$error && submitted" class="mb-3">-->
            <!--                <span id="companyConvention-error" v-for="(error, index) of v$.companyConvention.$errors" :key="index">-->
            <!--                  <InlineMessage>{{error.$message}}</InlineMessage>-->
            <!--                </span>-->
            <!--          </div>-->
          </div>
          <div class="flex flex-row justify-center align-items-center mb-3">
            <label for="companyLogo" class="w-2 block text-900 font-medium"> {{ $t('admin.params.company.logoLabel') }}</label>
            <img v-if="sourceLogo" :src="sourceLogo" width="60" height="60" class="cursor-pointer mr-4"/>
            <FileUpload
                v-if="hasAccess($route.name, true)"
                id="companyLogo"
                ref="logo"
                mode="basic"
                accept="image/*"
                :choose-label="$t('admin.params.company.logoImportLabel')"
                :maxFileSize="10000000"
                auto
                custom-upload
                @uploader="onUpload">
            </FileUpload>
          </div>
          <div class="flex flex-column mb-4">
            <label for="documentTypes" :class="{'p-error':v$.documentTypes.$invalid && submitted}" class="w-full block underline text-900 font-medium mb-3"> {{ $t('admin.params.company.documentTypes') }}</label>
            <Chips id="documentTypes" v-model="documentTypes" :class="{'p-invalid':v$.documentTypes.$invalid && submitted}" class="w-5">
              <template #chip="slotProps">
                <div>
                  <span>{{ slotProps.value }}</span>
                </div>
              </template>
            </Chips>
            <div v-if="v$.documentTypes.$error && submitted" class="mb-3">
            <span id="commercialSources-error" v-for="(error, index) of v$.documentTypes.$errors" :key="index">
              <InlineMessage>{{error.$message}}</InlineMessage>
            </span>
            </div>
          </div>

          <label class="w-full block text-900 underline font-medium mb-3">Partage de documents</label>
          <SocietyDocumentsSharer class="mb-4"/>

          <div class="mb-4 w-8">
            <label class="w-full block text-900 underline font-medium mb-3">Bas de page des contrats générés automatiquement</label>
            <Editor v-model="footerTemplate" editorStyle="height: 320px;"/>
          </div>
          <div v-if="hasAccess($route.name, true)" class="float-button">
            <Button :label="$t('admin.params.company.registerButton')" type="submit" class="w-full p-3"></Button>
          </div>
        </div>
      </form>
      <div class="spacer"/>
    </div>
  </div>
</template>

<script>
import { useVuelidate } from "@vuelidate/core";
import { helpers, required } from "@vuelidate/validators";
import Alert from "@/utils/Alert";
import { mapState } from "vuex";
import {
  disablePComponentsOfCurrentPage,
  formatHtmlToPdf,
  getBase64FromDownloadedFile,
  getBase64FromUploadedFile,
  hasAccess,
  formatSiret, REGEX_VALIDATORS
} from "@/utils/Misc";

import SocietyDocumentsSharer from "@/components/SocietyDocumentsSharer.vue";
import PageTitle from "@/components/PageTitle";

export default {
  components: {SocietyDocumentsSharer, PageTitle},
  setup: () => ({ v$: useVuelidate() }),
  name: "Entreprise",
  data () {
    return {
      documentTypes: [],
      hasAccess: hasAccess,
      companySocialRaison: '',
      companyCommercialName: '',
      companyStatus: '',
      companyCapital: '',
      companyAddress: '',
      companyZipCode: '',
      companyCity: '',
      companyCityRegistrationRCS: '',
      // companyRcs: '',
      companySiret: '',
      companyRepresentative: '',
      companyEmail: '',
      companyPhone: '',
      companyProfession: '',
      companyConvention: '',
      companyLogo: '', // save the id but show the file as input
      sourceLogo: '',
      uploadedLogo: null,
      submitted: false,
      searchResultDescription: '',

      companyLoading: false,
      companySuggestions: [],

      footerTemplate: '',

      companyConventionOptions: []
    }
  },
  async created () {
    this.documentTypes = this.settings.documentTypes
    await this.initializeFields()
  },
  computed: {
    ...mapState({
      settings: state => state.misc.settings.Entreprise,
      constants: state => state.constants
    })
  },
  mounted () {
    this.$nextTick(() => {
      if (!hasAccess(this.$route.name, true)) disablePComponentsOfCurrentPage(document)
    })
  },
  validations() {
    return {
      // todo: later on describe the validation precisely, if so don't forget "*" after each require fields
      companySocialRaison: {
        required: helpers.withMessage(this.$t('admin.params.company.requiredField'), required),
      },
      companyCommercialName: {
        required: helpers.withMessage(this.$t('admin.params.company.requiredField'), required),
      },
      companyStatus: {
        required: helpers.withMessage(this.$t('admin.params.company.requiredField'), required),
      },
      companyCapital: {
        required: helpers.withMessage(this.$t('admin.params.company.requiredField'), required),
      },
      companyAddress: {
        required: helpers.withMessage(this.$t('admin.params.company.requiredField'), required),
      },
      companyZipCode: {
        required: helpers.withMessage(this.$t('admin.params.company.requiredField'), required),
      },
      companyCity: {
        required: helpers.withMessage(this.$t('admin.params.company.requiredField'), required),
      },
      companyCityRegistrationRCS: {
        required: helpers.withMessage(this.$t('admin.params.company.requiredField'), required),
      },
      // companyRcs: {
      //   required: helpers.withMessage(this.$t('admin.params.company.requiredField'), required),
      // },
      companySiret: {
        required: helpers.withMessage(this.$t('admin.params.company.requiredField'), required),
      },
      companyRepresentative: {
        required: helpers.withMessage(this.$t('admin.params.company.requiredField'), required),
      },
      companyProfession: {
        required: helpers.withMessage(this.$t('admin.params.company.requiredField'), required),
      },
      companyConvention: {
        required: helpers.withMessage(this.$t('admin.params.company.requiredField'), required),
      },
      companyEmail: {
        required: helpers.withMessage(this.$t('admin.params.company.requiredField'), required),
      },
      companyPhone: {
        required: helpers.withMessage(this.$t('admin.params.company.requiredField'), required),
      },
      documentTypes: {}
    }
  },
  methods: {
    formatSiret,
    async autoFillCompany (event) {
      let company = event.value
      this.companySocialRaison = company.nom_raison_sociale
      this.companyCommercialName = company.nom_raison_sociale

      let results = await this.$store.dispatch('apigouv/natureJuridique', company.nature_juridique)
      this.companyStatus = results[0]?.fields.libelle?? ''

      this.companyAddress = `${company.siege.numero_voie} ${company.siege.type_voie} ${company.siege.libelle_voie}`
      this.companyZipCode = company.siege.code_postal
      this.companyCity = company.siege.libelle_commune
      this.companySiret = company.siege.siret
    },
    async getAutocomplete (e) {
      this.searchResultDescription = 'Recherche en cours...'
      if (this.timeout) clearTimeout(this.timeout)
      this.timeout = setTimeout(async () => {
        const response = await this.$store.dispatch('apigouv/autocompleteEntreprise', e.value)
        this.companyLoading = false
        // console.log(response)
        this.companySuggestions = response
        this.searchResultDescription = response.length === 0 ? 'Aucune entreprise n\'a été trouvée' : ''
      }, 1000)
    },
    async initializeFields () {
      this.companySocialRaison = this.settings.raisonSociale
      this.companyCommercialName = this.settings.nomCommercial
      this.companyStatus = this.settings.statutJuridique
      this.companyCapital = this.settings.capital
      this.companyAddress = this.settings.adresse
      this.companyZipCode = this.settings.codePostal
      this.companyCity = this.settings.ville
      this.companyCityRegistrationRCS = this.settings.villeImmatriculationRcs
      // this.companyRcs = this.settings.rcs
      this.companySiret = this.settings.siret
      this.companyRepresentative = this.settings.representant
      this.companyEmail = this.settings.email
      this.companyPhone = this.settings.phone
      this.companyProfession = this.settings.metier
      this.footerTemplate = this.settings.footerTemplate
      // setting collective convention on syntec by default if there's no existing convention set up properly
      this.companyConvention = this.settings.convention !== this.constants.COLLECTIVE_CONVENTION.SYNTEC && this.settings.convention !== this.constants.COLLECTIVE_CONVENTION.PORTAGE ?
          this.constants.COLLECTIVE_CONVENTION.SYNTEC : this.settings.convention
      this.companyLogo = this.settings.logo // just an id
      if (this.settings.logo) {
        this.sourceLogo = await this.getLogo(this.settings.logo)
      }
      this.companyConventionOptions = [
        { value: this.constants.COLLECTIVE_CONVENTION.SYNTEC, name: 'Syntec' },
        { value: this.constants.COLLECTIVE_CONVENTION.PORTAGE, name: 'Portage' }
      ]
      this.documentTypes = this.settings.documentTypes
    },
    async handleSubmit (isFormValid) {
      this.submitted = true

      if (!isFormValid) {
        Alert.errorMessage(this, 'fieldRequired', {symbole: '(*)'})
        return
      }

      if (!(REGEX_VALIDATORS.EMAIL.test(this.companyEmail))) {
        Alert.errorMessage(this, 'invalidEmail')
        return
      }

      try {
        if (this.uploadedLogo !== null) {
          // change logo only when there is a file that has been uploaded
          const newLogo = await this.$store.dispatch('file/uploadFile', this.uploadedLogo)
          this.companyLogo = newLogo.id
        }

        const payload = {
          Entreprise: {
            ...this.settings,
            raisonSociale: this.companySocialRaison,
            nomCommercial: this.companyCommercialName,
            statutJuridique: this.companyStatus,
            capital: this.companyCapital,
            adresse: this.companyAddress,
            codePostal: this.companyZipCode,
            ville: this.companyCity,
            villeImmatriculationRcs: this.companyCityRegistrationRCS,
            // rcs: this.companyRcs,
            siret: this.companySiret,
            representant: this.companyRepresentative,
            email: this.companyEmail,
            phone: this.companyPhone,
            metier: this.companyProfession,
            convention: this.companyConvention,
            logo: this.companyLogo,
            documentTypes: this.documentTypes,
            footerTemplate: formatHtmlToPdf(this.footerTemplate)
          }
        }
        await this.$store.dispatch('misc/updateSettings', payload)
        this.$toast.add({severity:'success', summary:'Succès', detail:'Paramètres sauvegardés', life: 3000});
      } catch (error) {
        this.$toast.add({severity:'error', summary:'Erreur', detail:'Une erreur s\'est produite', life: 3000});
      }
    },
    // todo: make a better validation of the image, show the image uploaded ?
    async onUpload (file) {
      getBase64FromUploadedFile(file, (result, form) => {
        this.uploadedLogo = form
        this.sourceLogo = URL.createObjectURL(file.files[0])
      })
    },
    async getLogo (fileId) {
      this.$store.state.misc.loading = true
      if (typeof fileId === 'string') {
        const downloadedLogo = await this.$store.dispatch('file/downloadFile', { id: fileId })
        getBase64FromDownloadedFile(downloadedLogo, (result) => {
          this.sourceLogo = result
        })
      }
      this.$store.state.misc.loading = false
    },
  }
}
</script>

<style scoped>

</style>
